<template>
    <CModal
        :show.sync="users_selectable_modal"
        color="primary"
        size="lg"
        :closeOnBackdrop="false"
        >
        <template #header>
            <h5> <font-awesome-icon icon="user"/> Users list </h5>
            <CButton 
                class="pull-right" 
                color="light" 
                shape="pill" 
                size="sm"
                @click="users_selectable_modal = false"
            >
                <font-awesome-icon icon="times"/>
            </CButton>
        </template>
        
        <template #body-wrapper>
            <CCardBody>
                <CDataTable
                    :items="users"
                    :fields="users_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    items-per-page-select
                    border
                    sorter
                    pagination
                    @row-clicked="rowClicked"
                    :loading="table_loading"
                >
                    <template #select="{item}">
                        <td>
                            <p-check class="p-icon p-bigger"
                                color="success" 
                                v-model="selected_users" 
                                :value="item.user_id"
                            >
                                <font-awesome-icon class="icon" icon="check"/>
                            </p-check> 
                        </td>
                    </template>
                    <template #id="{index}">
                        <td>
                            {{index+1}}
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </template>
        <template #footer>
            <CButton color="secondary" @click="users_selectable_modal= false">
                <font-awesome-icon class="icon" icon="times"/> Cancel
            </CButton>
            <CButton :color="isReplace ? 'warning' : 'primary'" @click="addUsers()" :disabled="saving">
                <font-awesome-icon 
                    class="icon" :icon="!saving ? 'save' : 'circle-notch'"
                    :pulse="saving"/> 
                    {{ isReplace ? 'Replace User(s)' : 'Add User(s)' }}
            </CButton>
        </template>
    </CModal>
</template>
<script>
export default {
    name: 'UsersSelectableModal',
    data() {
        return {
            saving: false,
            table_loading: true,
            users_selectable_modal: false,
            selected_users: [],
            clear_selected: false,
            selected: {
                id: null,
                name: null,
            },
            users: [],
            isReplace : false,
            users_fields: [
                { 
                    key: 'select', 
                    label: '', 
                    _style: 'min-width:1%', 
                    sorter: false,
                    filter: false
                },
                {key: 'id', label: 'ID'},
                {key: 'employee_number', label: 'Employee Number'},
                {key: 'name', label: 'Name'},
                {key: 'department'},
            ]
        }
    },
    created() {
        this.$parent.$on('show_users_selectable_modal', this.getUsers);
        this.$parent.$on('show_users_selectable_modal_closed', this.addingSuccess);
    },
    methods: {
        /**
         * This getUsers function returns list of users
         * 
         * @param object parameter
         * The object has default value of null.
         * 
         * id of user currently selected.
         * selected: [] 
         * 
         * @return the required data
         */
        getUsers: function(selected=null, replace) {
            this.isReplace = replace;
            this.$Progress.start()
            axios.post('/users/list-with-parameter/', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.users = []
                    for(let i = 0; i < response.data.data.length; i++) {
                        
                        let option = {
                            user_id: response.data.data[i].id,
                            employee_number: response.data.data[i].employee_number,
                            name: `${response.data.data[i].first_name} ${response.data.data[i].last_name}${response.data.data[i].suffix ? ' '+response.data.data[i].suffix: ''}`,
                            department: response.data.data[i].department ? response.data.data[i].department.name : 'N/a',
                        }
                        if(!selected.includes(response.data.data[i].id)) {
                            this.users.push(option)
                        }
                    }
                    // this.users = response.data.data
                    this.table_loading = false
                    this.$Progress.finish()
                }
            })
        },
        rowClicked: function(item, index, column, e) {
            if(column != 'select') {
                let i = this.selected_users.indexOf(item.user_id);
                if (i !== -1) {
                    this.selected_users.splice(i, 1)
                } else {
                    this.selected_users.push(item.user_id)
                }
            }
            this.saving = false;
        },
        addingSuccess: function () { 
            this.saving = false
            this.users_selectable_modal = false;
        },
        addUsers: function() {
            this.saving = true
            this.$emit('selectedUser', this.selected_users)
        }
    },
    watch: {
        users_selectable_modal: function (value) {
            if(!value) {
                this.selected_users = [];
                this.saving = false
            }
        }
    }
}
</script>